import type { PAGES_WITH_CALCULATOR } from './pagesWithCalculator';

interface Headings {
  title: string;
  subtitle: string;
}

/**
 * todo(1321): Убрать хардкод урлов страниц
 * @link https://sravni-corp.atlassian.net/browse/MFO-1321
 */
export const CALCULATOR_HEADINGS_BY_ROUTE: Record<(typeof PAGES_WITH_CALCULATOR)[number], Headings> = {
  '/kz/zaimy/predlozheniya/': {
    title: 'Калькулятор займов в Казахстане',
    subtitle:
      'Калькулятор займов в Казахстане помогает рассчитать проценты и выбрать предложения, доступные на территории страны. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги быстро и удобно!',
  },
  '/zaimy/top/': {
    title: 'Калькулятор лучших займов',
    subtitle:
      'Калькулятор лучших займов создан для тех, кто ищет самые выгодные условия. С помощью калькулятора лучших займов вы легко сравните предложения {количество_предложений} со ставкой от 0% в день. Используйте калькулятор лучших займов, чтобы быстро получить деньги на карту по лучшим условиям.',
  },
  '/zaimy/24-chasa-onlayn/': {
    title: 'Калькулятор займов 24 часа онлайн',
    subtitle:
      'Калькулятор займов 24 часа онлайн поможет рассчитать проценты и выбрать самые выгодные условия для вашего займа. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги круглосуточно и без лишних документов на ваш банковский счет или карту!',
  },
  '/zaimy/300-na-kivi/': {
    title: 'Калькулятор займов 300 рублей на Киви',
    subtitle:
      'Калькулятор займов на 300 рублей для Киви-кошелька позволяет быстро рассчитать проценты и выбрать лучшие условия для небольшого займа. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги на ваш Киви-кошелек моментально без лишних сложностей!',
  },
  '/zaimy/50000-na-kivi/': {
    title: 'Калькулятор займов 50000 рублей на Киви',
    subtitle:
      'Калькулятор займов на 50000 рублей на Киви-кошелек поможет рассчитать проценты и подобрать оптимальные условия для вашего займа. Доступны {количество_предложений} от {количество_организаций_где}, минимальная ставка начинается от {минимальная_ставка}% в день. Мгновенно получите средства на ваш электронный кошелек!',
  },
  '/zaimy/bez-predoplaty/': {
    title: 'Калькулятор займов без предоплаты',
    subtitle:
      'Калькулятор займов без предоплаты поможет вам рассчитать сумму переплаты и выбрать выгодные условия для займа. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги быстро и без скрытых платежей!',
  },
  '/zaimy/bez-protsentov-na-30-dnej/': {
    title: 'Калькулятор займов без процентов на 30 дней',
    subtitle:
      'Калькулятор займов без процентов на 30 дней поможет вам выбрать подходящее предложение с нулевой переплатой. На данный момент доступны {количество_предложений} от {количество_организаций_где} МФО. Получите займ без процентов на короткий срок и верните только сумму займа!',
  },
  '/zaimy/bez-pts/': {
    title: 'Калькулятор займов без ПТС',
    subtitle:
      'Калькулятор займов без ПТС позволяет рассчитать проценты и выбрать условия без предоставления документов на автомобиль. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги быстро без необходимости залога авто.',
  },
  '/zaimy/bezrabotnym-na-kivi/': {
    title: 'Калькулятор займов безработным на Киви',
    subtitle:
      'Калькулятор займов для безработных на Киви-кошелек поможет рассчитать условия займа даже без постоянного дохода. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги на ваш Киви-кошелек без справок о доходах!',
  },
  '/zaimy/bez-snilsa/': {
    title: 'Калькулятор займов без СНИЛС',
    subtitle:
      'С помощью калькулятора займов без СНИЛС вы сможете рассчитать сумму переплаты и выбрать лучшие условия. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Оформите займ без предоставления дополнительных документов.',
  },
  '/zaimy/bez-spisanija/': {
    title: 'Калькулятор займов без списания',
    subtitle:
      'Калькулятор займов без списания денег с карты поможет вам подобрать наиболее выгодные условия займа. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Убедитесь, что ваши средства под контролем — никакого автоматического списания.',
  },
  '/zaimy/bez-telefona/': {
    title: 'Калькулятор займов без телефона',
    subtitle:
      'Калькулятор займов без предоставления номера телефона позволяет рассчитать проценты и выбрать подходящие условия. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите займ без звонков и дополнительных проверок.',
  },
  '/zaimy/bez-ukazanija-raboti/': {
    title: 'Калькулятор займов без указания работы',
    subtitle:
      'Калькулятор займов без указания места работы позволяет быстро рассчитать условия займа. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите займ без предоставления справок о трудоустройстве!',
  },
  '/zaimy/bez-zaloga/': {
    title: 'Калькулятор займов без залога',
    subtitle:
      'Калькулятор займов без залога поможет рассчитать проценты и выбрать оптимальные условия для вашего займа. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги без необходимости предоставления залога.',
  },
  '/zaimy/bolshie-zaimy/': {
    title: 'Калькулятор больших займов',
    subtitle:
      'Калькулятор больших займов помогает рассчитать проценты и подобрать оптимальные условия. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите крупную сумму на длительный срок!',
  },
  '/zaimy/bystriy-na-kartu/': {
    title: 'Калькулятор быстрых займов на карту',
    subtitle:
      'Калькулятор быстрых займов на карту помогает рассчитать проценты и подобрать оптимальные условия. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Мгновенно получите деньги на вашу карту!',
  },
  '/zaimy/cherez-gosuslugi/': {
    title: 'Калькулятор займов через Госуслуги',
    subtitle:
      'Калькулятор займов через Госуслуги помогает рассчитать проценты и подобрать оптимальные условия. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Удобно оформите займ через Госуслуги!',
  },
  '/zaimy/srochno-250000/': {
    title: 'Калькулятор займов срочно на 250000 рублей',
    subtitle:
      'Калькулятор займов на 250000 рублей позволяет быстро рассчитать сумму переплаты и выбрать оптимальные условия. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги на карту для решения срочных финансовых задач!',
  },
  '/zaimy/srochno-3000/': {
    title: 'Калькулятор займов срочно на 3000 рублей',
    subtitle:
      'Калькулятор займов на 3000 рублей — это удобный инструмент для быстрого расчета процентов. Доступно {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Подберите выгодное предложение и получите деньги моментально!',
  },
  '/zaimy/srochno-30000/': {
    title: 'Калькулятор займов срочно на 30000 рублей',
    subtitle:
      'С помощью калькулятора займов на 30000 рублей вы сможете выбрать наиболее выгодное предложение. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Быстро получите деньги на карту или банковский счет!',
  },
  '/zaimy/srochno-300000/': {
    title: 'Калькулятор займов срочно на 300000 рублей',
    subtitle:
      'Калькулятор займов на 300000 рублей помогает рассчитать сумму займа и подобрать лучшие условия. На данный момент доступно {количество_предложений} от {количество_организаций_где} МФО. Минимальная ставка — от {минимальная_ставка}% в день. Получите деньги для крупных покупок или инвестиций!',
  },
  '/zaimy/srochno-35000/': {
    title: 'Калькулятор займов срочно на 35000 рублей',
    subtitle:
      'Калькулятор займов на 35000 рублей позволяет рассчитать проценты и выбрать оптимальные условия займа. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Быстро оформите займ и решите свои финансовые задачи!',
  },
  '/zaimy/srochno-4000/': {
    title: 'Калькулятор займов срочно на 4000 рублей',
    subtitle:
      'Калькулятор займов на 4000 рублей поможет быстро рассчитать переплату и выбрать лучшее предложение. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги на карту без лишних хлопот!',
  },
  '/zaimy/srochno-40000/': {
    title: 'Калькулятор займов срочно на 40000 рублей',
    subtitle:
      'С калькулятором займов на 40000 рублей вы сможете выбрать наиболее выгодное предложение. На сегодняшний день доступно {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Подайте заявку и получите деньги срочно!',
  },
  '/zaimy/srochno-500/': {
    title: 'Калькулятор займов срочно на 500 рублей',
    subtitle:
      'Калькулятор займов на 500 рублей позволяет рассчитать переплату и выбрать лучшее предложение среди множества вариантов. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Быстро получите небольшую сумму на карту!',
  },
  '/zaimy/srochno-5000/': {
    title: 'Калькулятор займов срочно на 5000 рублей',
    subtitle:
      'С калькулятором займов на 5000 рублей вы сможете мгновенно рассчитать проценты и выбрать выгодное предложение. Доступно {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги срочно и без сложностей!',
  },
  '/zaimy/srochno-50000/': {
    title: 'Калькулятор займов срочно на 50000 рублей',
    subtitle:
      'Калькулятор займов на 50000 рублей помогает рассчитать сумму займа и выбрать подходящее предложение. На сегодняшний день доступно {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги на карту для крупных покупок или решения срочных вопросов!',
  },
  '/zaimy/srochno-500000/': {
    title: 'Калькулятор займов срочно на 500000 рублей',
    subtitle:
      'Калькулятор займов на 500000 рублей поможет вам рассчитать проценты и подобрать оптимальные условия для крупной суммы. На выбор — {количество_предложений} от {количество_организаций_где} МФО. Минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги для крупных целей и важных приобретений!',
  },
  '/zaimy/srochno-6000/': {
    title: 'Калькулятор займов срочно на 6000 рублей',
    subtitle:
      'Калькулятор займов на 6000 рублей помогает рассчитать переплату и выбрать лучшие условия. Доступно {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги срочно и без лишних проверок!',
  },
  '/zaimy/srochno-60000/': {
    title: 'Калькулятор займов срочно на 60000 рублей',
    subtitle:
      'С помощью калькулятора займов на 60000 рублей вы сможете рассчитать проценты и подобрать оптимальное предложение. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги быстро на карту или счет!',
  },
  '/zaimy/srochno-7000/': {
    title: 'Калькулятор займов срочно на 7000 рублей',
    subtitle:
      'Калькулятор займов на 7000 рублей — ваш быстрый инструмент для расчета переплаты и выбора выгодных условий. Доступно {количество_предложений} от {количество_организаций_где} МФО. Минимальная ставка начинается от {минимальная_ставка}% в день. Оформите займ и получите деньги за считанные минуты!',
  },
  '/zaimy/srochno-70000/': {
    title: 'Калькулятор займов срочно на 70000 рублей',
    subtitle:
      'Калькулятор займов на 70000 рублей позволяет быстро рассчитать проценты и выбрать наиболее подходящее предложение. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Решите свои финансовые вопросы без лишних сложностей!',
  },
  '/zaimy/srochno-8000/': {
    title: 'Калькулятор займов срочно на 8000 рублей',
    subtitle:
      'Калькулятор займов на 8000 рублей поможет вам мгновенно рассчитать сумму переплаты и выбрать выгодное предложение. Доступно {количество_предложений} от {количество_организаций_где} МФО. Минимальная ставка — от {минимальная_ставка}% в день. Получите деньги быстро и удобно!',
  },
  '/zaimy/srochno-80000/': {
    title: 'Калькулятор займов срочно на 80000 рублей',
    subtitle:
      'С калькулятором займов на 80000 рублей вы сможете выбрать подходящее предложение и рассчитать проценты. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги на карту без лишних проверок!',
  },
  '/zaimy/srochno-9000/': {
    title: 'Калькулятор займов срочно на 9000 рублей',
    subtitle:
      'Калькулятор займов на 9000 рублей позволяет быстро рассчитать переплату и выбрать выгодные условия займа. Доступно {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги быстро и удобно!',
  },
  '/zaimy/srochno-90000/': {
    title: 'Калькулятор займов срочно на 90000 рублей',
    subtitle:
      'Калькулятор займов на 90000 рублей помогает рассчитать проценты и выбрать лучшее предложение. На сегодняшний день доступно {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Решите свои финансовые вопросы быстро и выгодно!',
  },
  '/zaimy/studentam-na-kartu-bez-otkaza/': {
    title: 'Калькулятор займов для студентов без отказа',
    subtitle:
      'Калькулятор займов для студентов без отказа поможет рассчитать сумму переплаты и выбрать предложение с высокой вероятностью одобрения. Доступно {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги даже без постоянного дохода!',
  },
  '/zaimy/uluchshenie-ki/': {
    title: 'Калькулятор займов для улучшения кредитной истории',
    subtitle:
      'Калькулятор займов для улучшения кредитной истории поможет вам рассчитать проценты и выбрать подходящее предложение для восстановления кредитного рейтинга. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Улучшайте свою кредитную историю с выгодой!',
  },
  '/zaimy/v-dolg/': {
    title: 'Калькулятор денег в долг',
    subtitle:
      'Калькулятор денег в долг позволяет рассчитать переплату и выбрать лучшее предложение для краткосрочного займа. Доступны {количество_предложений} от {количество_организаций_где} МФО. Минимальная ставка — от {минимальная_ставка}% в день. Получите деньги быстро на карту или счет!',
  },
  '/zaimy/v-dolg-na-yandex/': {
    title: 'Калькулятор денег в долг на Яндекс',
    subtitle:
      'Калькулятор займов на Яндекс позволяет рассчитать проценты и выбрать лучшие условия для перевода денег на ваш кошелек. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги на ваш Яндекс.Кошелек быстро и удобно!',
  },
  '/zaimy/voennosluzhashhim/': {
    title: 'Калькулятор займов для военнослужащих',
    subtitle:
      'Калькулятор займов для военнослужащих поможет рассчитать сумму переплаты и подобрать выгодные условия займа. Доступны {количество_предложений} от {количество_организаций_где} МФО. Минимальная ставка — от {минимальная_ставка}% в день. Получите деньги быстро и без лишних проверок!',
  },
  '/zaimy/vsem/': {
    title: 'Калькулятор займов для всех',
    subtitle:
      'Калькулятор займов для всех позволяет рассчитать проценты и выбрать подходящее предложение для любого заемщика. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги быстро и без ограничений!',
  },
  '/zaimy/za-chas/': {
    title: 'Калькулятор займов за час',
    subtitle:
      'Калькулятор займов за час поможет рассчитать проценты и выбрать выгодное предложение для быстрого займа. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги уже через час на карту или счет!',
  },
  '/zaimy/za-minutu/': {
    title: 'Калькулятор займов за минуту',
    subtitle:
      'С калькулятором займов за минуту вы сможете мгновенно рассчитать переплату и выбрать подходящее предложение. Доступно {количество_предложений} от {количество_организаций_где} МФО. Минимальная ставка — от {минимальная_ставка}% в день. Оформите займ и получите деньги в течение минуты!',
  },
  '/zaimy/zayavka-vo-vse-mikrozaimy-srazu/': {
    title: 'Калькулятор займов во все микроорганизации',
    subtitle:
      'Калькулятор займов во все микроорганизации поможет рассчитать проценты и выбрать предложения от нескольких МФО одновременно. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Подайте заявки сразу в несколько организаций и получите выгодные условия!',
  },
  '/zaimy/na-kivi/': {
    title: 'Калькулятор займов на Киви кошелек',
    subtitle:
      'Калькулятор займов на Киви-кошелек позволяет рассчитать проценты и подобрать лучшие условия для перевода денег на ваш электронный кошелек. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги на Киви моментально!',
  },
  '/zaimy/bez-protsentov/': {
    title: 'Калькулятор займов без процентов',
    subtitle:
      'Калькулятор займов без процентов позволяет выбрать предложения с нулевой переплатой. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите займ без переплаты на выгодных условиях!',
  },
  '/zaimy/bez-otkaza/': {
    title: 'Калькулятор займов без отказа онлайн',
    subtitle:
      'Калькулятор займов без отказа онлайн помогает рассчитать проценты и выбрать предложения с высокой вероятностью одобрения. Доступно {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Оформите займ онлайн и получите деньги без отказа!',
  },
  '/zaimy/bez-podtverzhdenija-lichnosti/': {
    title: 'Калькулятор займов без подтверждения личности и карты',
    subtitle:
      'Калькулятор займов без подтверждения личности и карты позволяет рассчитать проценты и выбрать предложения без необходимости предоставления документов. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги быстро и без проверок!',
  },
  /**
   * todo: Был баг, тред - https://sravni-corp.slack.com/archives/C06FGK2UE77/p1735285626451789
   * Решили пока скрыть, после нг будем разбираться
   * @link https://sravni-corp.atlassian.net/browse/MFO-1408
   */
  // '/zaimy/na-kartu/': {
  //     title: 'Калькулятор займов на карту',
  //     subtitle:
  //         'С помощью калькулятора займов на карту вы сможете рассчитать проценты и выбрать выгодные предложения. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги на карту моментально!',
  // },
  '/zaimy/bezrabotnym/': {
    title: 'Калькулятор займов безработным',
    subtitle:
      'Калькулятор займов для безработных позволяет рассчитать проценты и выбрать предложения, доступные без подтверждения дохода. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги без справок о доходах!',
  },
  '/zaimy/bez-propiski-i-vremennoy-registracii/': {
    title: 'Калькулятор займов без прописки',
    subtitle:
      'Калькулятор займов без прописки помогает выбрать предложения, доступные без предоставления регистрации. Доступно {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите займ на карту без дополнительных документов!',
  },
  '/zaimy/bez-poruchiteley-i-spravok/': {
    title: 'Калькулятор займов без справок и поручителей',
    subtitle:
      'Калькулятор займов без справок и поручителей позволяет рассчитать переплату и выбрать лучшие предложения. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги без лишних формальностей!',
  },
  '/zaimy/': {
    title: 'Калькулятор микрозаймов',
    subtitle:
      'Калькулятор микрозаймов помогает рассчитать проценты и выбрать выгодные условия для небольшого займа. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите микрозайм быстро и без лишних проверок!',
  },
  '/zaimy/v-dolg-na-kartu/': {
    title: 'Калькулятор денег в долг на карту',
    subtitle:
      'Калькулятор денег в долг на карту позволяет рассчитать проценты и выбрать оптимальные условия займа. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги на карту моментально и без лишних формальностей!',
  },
  '/zaimy/s-plokhoj-kreditnoj-istoriej/': {
    title: 'Калькулятор займов с плохой кредитной историей без отказа',
    subtitle:
      'Калькулятор займов с плохой кредитной историей поможет выбрать предложения с высокой вероятностью одобрения. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Улучшите свою финансовую ситуацию, даже если кредитная история испорчена!',
  },
  '/zaimy/mfo-online/': {
    title: 'Калькулятор списка всех МФО для займа онлайн на карту',
    subtitle:
      'Калькулятор списка всех МФО для займа онлайн на карту позволяет рассчитать проценты и сравнить предложения от множества организаций. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги на карту в удобное время!',
  },
  '/zaimy/mini-onlajn/': {
    title: 'Калькулятор мини займов',
    subtitle:
      'Калькулятор мини займов помогает рассчитать проценты для небольших сумм и выбрать подходящие условия. Доступно {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите небольшую сумму быстро и без проверок!',
  },
  '/zaimy/kratkosrochnye/': {
    title: 'Калькулятор краткосрочных займов',
    subtitle:
      'Калькулятор краткосрочных займов помогает рассчитать проценты и выбрать подходящие условия для займа на короткий срок. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Быстрое решение для ваших финансовых вопросов!',
  },
  '/zaimy/bez-proverok/': {
    title: 'Калькулятор займов онлайн на карту без проверок',
    subtitle:
      'Калькулятор займов онлайн на карту без проверок позволяет рассчитать проценты и выбрать предложение без лишних формальностей. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги без проверок кредитной истории!',
  },
  '/zaimy/na-kartu-maestro/': {
    title: 'Калькулятор займов на карту Маэстро',
    subtitle:
      'Калькулятор займов на карту Маэстро позволяет рассчитать проценты и выбрать подходящие предложения. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги на карту Маэстро быстро и без сложностей!',
  },
  '/zaimy/na-kartu-mgnovenno-kruglosutochno/': {
    title: 'Калькулятор займов на карту мгновенно круглосуточно без отказа',
    subtitle:
      'Калькулятор займов на карту круглосуточно поможет рассчитать проценты и выбрать предложения с высокой вероятностью одобрения. Доступно {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги мгновенно в любое время!',
  },
  '/zaimy/na-kartu-sberbanka/': {
    title: 'Калькулятор займов на карту Сбербанка',
    subtitle:
      'Калькулятор займов на карту Сбербанка поможет рассчитать проценты и выбрать подходящие условия. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги быстро на карту Сбербанка!',
  },
  '/zaimy/na-kivi-s-plohoy-ki/': {
    title: 'Калькулятор займов на Киви с плохой кредитной историей',
    subtitle:
      'Калькулятор займов на Киви для заемщиков с плохой кредитной историей поможет рассчитать проценты и выбрать предложение с высокой вероятностью одобрения. Доступно {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги на Киви-кошелек без отказа!',
  },
  '/zaimy/novye/': {
    title: 'Калькулятор новых займов {год} на карту',
    subtitle:
      'Калькулятор новых займов {год} на карту позволяет рассчитать проценты и выбрать актуальные предложения. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги на карту на лучших условиях!',
  },
  '/zaimy/onlain/': {
    title: 'Калькулятор займов онлайн',
    subtitle:
      'Калькулятор займов онлайн помогает рассчитать проценты и выбрать подходящее предложение без необходимости посещать офис. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги онлайн быстро и удобно!',
  },
  '/zaimy/pod-nizkij-procent/': {
    title: 'Калькулятор займов под низкий процент',
    subtitle:
      'Калькулятор займов под низкий процент поможет рассчитать сумму переплаты и выбрать наиболее выгодные условия. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Экономьте на процентах и получите деньги на лучших условиях!',
  },
  '/zaimy/pod-zalog-nedvizhimosti/': {
    title: 'Калькулятор займов под залог недвижимости',
    subtitle:
      'Калькулятор займов под залог недвижимости поможет рассчитать проценты и выбрать выгодные условия для крупных сумм. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги под залог жилья на выгодных условиях!',
  },
  '/zaimy/na-kartu-s-18-let-online/': {
    title: 'Калькулятор займов с 18 лет на карту',
    subtitle:
      'Калькулятор займов с 18 лет позволяет рассчитать проценты и выбрать подходящие предложения для молодых заемщиков. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги на карту быстро и без ограничений!',
  },
  '/zaimy/momentalny/': {
    title: 'Калькулятор займов с моментальным решением',
    subtitle:
      'Калькулятор займов с моментальным решением поможет рассчитать проценты и выбрать подходящее предложение. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги быстро без ожидания!',
  },
  '/zaimy/s-prosrochkami/': {
    title: 'Калькулятор займов с открытыми просрочками',
    subtitle:
      'Калькулятор займов с открытыми просрочками позволяет рассчитать проценты и выбрать предложения с высокой вероятностью одобрения. Доступно {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги даже при наличии текущих долгов!',
  },
  '/zaimy/nalichnymi/': {
    title: 'Калькулятор займов наличными',
    subtitle:
      'Калькулятор займов наличными позволяет рассчитать проценты и выбрать предложения с выдачей денег наличными. Доступно {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите наличные быстро и без лишних документов!',
  },
  '/zaimy/za-15-minut/': {
    title: 'Калькулятор займов за 15 минут',
    subtitle:
      'С калькулятором займов за 15 минут вы сможете быстро рассчитать проценты и выбрать подходящее предложение. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги на карту всего за 15 минут!',
  },
  '/kz/zaimy/': {
    title: 'Калькулятор займов в Казахстане',
    subtitle:
      'Калькулятор займов в Казахстане помогает рассчитать проценты и выбрать предложения, доступные на территории страны. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги быстро и удобно!',
  },
  '/zaimy/1000-na-kivi/': {
    title: 'Калькулятор займов 1000 рублей на Киви',
    subtitle:
      'Калькулятор займов на 1000 рублей для Киви-кошелька позволяет рассчитать переплату и выбрать лучшие условия. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги на Киви моментально!',
  },
  '/zaimy/2000-na-kivi/': {
    title: 'Калькулятор займов 2000 рублей на Киви',
    subtitle:
      'Калькулятор займов на 2000 рублей для Киви-кошелька помогает рассчитать проценты и выбрать выгодное предложение. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Быстрое решение для ваших финансовых задач!',
  },
  '/zaimy/3000-na-kivi/': {
    title: 'Калькулятор займов 3000 рублей на Киви',
    subtitle:
      'Калькулятор займов на 3000 рублей для Киви-кошелька позволяет быстро рассчитать проценты и подобрать подходящее предложение. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги на Киви-кошелек без ожидания!',
  },
  '/zaimy/5000-na-kivi/': {
    title: 'Калькулятор займов 5000 рублей на Киви',
    subtitle:
      'Калькулятор займов на 5000 рублей для Киви-кошелька помогает рассчитать проценты и выбрать наиболее выгодные условия. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги на Киви легко и удобно!',
  },
  '/zaimy/500-na-kivi/': {
    title: 'Калькулятор займов от 500 рублей на Киви',
    subtitle:
      'Калькулятор займов от 500 рублей для Киви-кошелька позволяет рассчитать проценты и выбрать предложение для небольшого займа. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Мгновенно получите деньги на Киви-кошелек!',
  },
  '/zaimy/70-let/': {
    title: 'Калькулятор займов для пенсионеров до 70 лет',
    subtitle:
      'Калькулятор займов для пенсионеров до 70 лет помогает рассчитать проценты и выбрать подходящие предложения. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги без лишних проверок!',
  },
  '/zaimy/75-let/': {
    title: 'Калькулятор займов для пенсионеров до 75 лет',
    subtitle:
      'Калькулятор займов для пенсионеров до 75 лет позволяет рассчитать переплату и выбрать выгодные условия займа. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги быстро и без лишних формальностей!',
  },
  '/zaimy/80-let/': {
    title: 'Калькулятор микрозаймов пенсионерам до 80 лет',
    subtitle:
      'Калькулятор микрозаймов пенсионерам до 80 лет помогает рассчитать проценты и выбрать доступные предложения. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Финансовая помощь для пенсионеров без ожидания!',
  },
  '/zaimy/85-let/': {
    title: 'Калькулятор займов до 85 лет на карту',
    subtitle:
      'Калькулятор займов до 85 лет на карту помогает рассчитать проценты и выбрать предложения для заемщиков пожилого возраста. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги без сложностей!',
  },
  '/zaimy/besplatno/': {
    title: 'Калькулятор первого займа бесплатно',
    subtitle:
      'Калькулятор первого займа бесплатно позволяет рассчитать условия займа с нулевой переплатой. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите первый займ без процентов!',
  },
  '/zaimy/bez-documentov/': {
    title: 'Калькулятор займов на карту без документов',
    subtitle:
      'Калькулятор займов на карту без документов помогает рассчитать проценты и выбрать предложения без необходимости предоставлять справки. Доступно {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги без лишних формальностей!',
  },
  '/zaimy/bez-elektronnoj-pochty/': {
    title: 'Калькулятор займов без электронной почты',
    subtitle:
      'Калькулятор займов без электронной почты позволяет рассчитать проценты и выбрать подходящее предложение. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите займ без необходимости указывать email!',
  },
  '/zaimy/bez-foto/': {
    title: 'Калькулятор займов без фото паспорта',
    subtitle:
      'Калькулятор займов без фото паспорта позволяет рассчитать проценты и выбрать предложения без необходимости загружать документы. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги быстро и без сложностей!',
  },
  '/zaimy/bez-karty/': {
    title: 'Калькулятор займов без карты',
    subtitle:
      'Калькулятор займов без карты позволяет рассчитать проценты и выбрать предложения с выдачей денег на другие реквизиты. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги без необходимости указывать карту!',
  },
  '/zaimy/bez-kreditnoy-istorii/': {
    title: 'Калькулятор займов без кредитной истории',
    subtitle:
      'Калькулятор займов без кредитной истории помогает рассчитать проценты и выбрать предложения для заемщиков, не имеющих кредитного рейтинга. Доступно {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день',
  },
  '/zaimy/bez-obmana/': {
    title: 'Калькулятор займов без обмана',
    subtitle:
      'Калькулятор займов без обмана поможет рассчитать проценты и выбрать надежные предложения от проверенных организаций. Доступно {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги без скрытых условий и неприятных сюрпризов!',
  },
  '/zaimy/bez-otkaza-100-procentov-odobreniya/': {
    title: 'Калькулятор займов с одобрением 100% процентов',
    subtitle:
      'Калькулятор займов с одобрением 100% поможет рассчитать проценты и выбрать предложения с гарантией получения денег. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги без отказов и ожидания!',
  },
  '/zaimy/bezotkazanye/': {
    title: 'Калькулятор безотказных займов',
    subtitle:
      'Калькулятор безотказных займов позволяет рассчитать проценты и выбрать предложения с высокой вероятностью одобрения. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги, независимо от вашей кредитной истории!',
  },
  '/zaimy/bez-pasporta/': {
    title: 'Калькулятор займов без паспорта',
    subtitle:
      'Калькулятор займов без паспорта поможет рассчитать проценты и выбрать предложения без необходимости предоставлять документ. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги быстро и без лишних формальностей!',
  },
  '/zaimy/bez-podpisok/': {
    title: 'Калькулятор займов без платных подписок',
    subtitle:
      'Калькулятор займов без платных подписок помогает выбрать предложения, где отсутствуют скрытые услуги и дополнительные платежи. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги без неожиданных списаний!',
  },
  '/zaimy/bez-posrednikov/': {
    title: 'Калькулятор займов без посредников',
    subtitle:
      'Калькулятор займов без посредников позволяет рассчитать проценты и выбрать предложения напрямую от МФО. Доступно {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги быстро и без переплат за услуги посредников!',
  },
  '/zaimy/dengi-v-dolg-bez-proverki-ki/': {
    title: 'Калькулятор денег в долг на карту срочно без проверки',
    subtitle:
      'Калькулятор денег в долг на карту срочно без проверки помогает рассчитать проценты и выбрать подходящие предложения. Доступно {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги без проверки кредитной истории!',
  },
  '/zaimy/dengi-v-dolg-na-kivi/': {
    title: 'Калькулятор денег в долг на Киви кошелек',
    subtitle:
      'Калькулятор денег в долг на Киви кошелек позволяет рассчитать проценты и выбрать выгодные условия. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги на ваш Киви-кошелек моментально!',
  },
  '/zaimy/dengi-v-dolg-v-20-let/': {
    title: 'Калькулятор займов с 20 лет на карту',
    subtitle:
      'Калькулятор займов с 20 лет на карту помогает рассчитать проценты и выбрать подходящие предложения для молодых заемщиков. Доступно {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги на карту быстро и без сложностей!',
  },
  '/zaimy/dengi-v-rassrochku-bez-procentov/': {
    title: 'Калькулятор денег в рассрочку',
    subtitle:
      'Калькулятор денег в рассрочку позволяет рассчитать проценты и выбрать предложения с помесячным погашением. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги и возвращайте их удобными платежами!',
  },
  '/zaimy/dlja-pogashenija/': {
    title: 'Калькулятор займов на погашение других займов',
    subtitle:
      'Калькулятор займов на погашение других займов поможет рассчитать условия и выбрать подходящее предложение для рефинансирования. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Решите свои финансовые проблемы выгодно!',
  },
  '/zaimy/dlya-grazhdan-sng/': {
    title: 'Калькулятор займов онлайн для граждан СНГ',
    subtitle:
      'Калькулятор займов онлайн для граждан СНГ помогает рассчитать проценты и выбрать подходящие предложения. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги, независимо от гражданства!',
  },
  '/zaimy/dolg-na-kartu-sberbanka/': {
    title: 'Калькулятор денег в долг на карту Сбербанка',
    subtitle:
      'Калькулятор денег в долг на карту Сбербанка помогает рассчитать проценты и выбрать предложения с быстрым переводом. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги на карту Сбербанка быстро и удобно!',
  },
  '/zaimy/dolgosrochnye/': {
    title: 'Калькулятор долгосрочных займов',
    subtitle:
      'Калькулятор долгосрочных займов позволяет рассчитать проценты и выбрать подходящие предложения с длительным сроком погашения. Доступно {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги на выгодных условиях!',
  },
  '/zaimy/dolzhnikam/': {
    title: 'Калькулятор займов должникам',
    subtitle:
      'Калькулятор займов для должников позволяет рассчитать проценты и выбрать предложения с высокой вероятностью одобрения. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги даже при наличии долгов!',
  },
  '/zaimy/ehkspress-na-kartu/': {
    title: 'Калькулятор экспресс займов на карту',
    subtitle:
      'Калькулятор экспресс займов на карту помогает рассчитать проценты и выбрать предложения с моментальной выдачей. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги на карту в считаные минуты!',
  },
  '/zaimy/inostrancam/': {
    title: 'Калькулятор займов иностранному гражданину',
    subtitle:
      'Калькулятор займов для иностранного гражданина поможет рассчитать проценты и выбрать предложения, доступные нерезидентам. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги на карту, даже если у вас нет гражданства РФ!',
  },
  '/zaimy/kalkuljator-microzaimov/': {
    title: 'Калькулятор микрозаймов',
    subtitle:
      'Калькулятор микрозаймов помогает рассчитать проценты и выбрать оптимальные условия для небольших сумм. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги быстро и без проверок!',
  },
  '/zaimy/kivi-yandex/': {
    title: 'Калькулятор займов на Киви кошелек и Яндекс Деньги',
    subtitle:
      'Калькулятор займов на Киви кошелек и Яндекс Деньги позволяет рассчитать проценты и выбрать предложения для перевода на электронные кошельки. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги на ваш кошелек мгновенно!',
  },
  '/zaimy/kruglosutochno/': {
    title: 'Калькулятор займов на карту круглосуточно',
    subtitle:
      'Калькулятор займов на карту круглосуточно помогает рассчитать проценты и выбрать предложения с мгновенной выдачей в любое время суток. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги, когда вам это нужно!',
  },
  '/zaimy/maloizvestnye-mfo/': {
    title: 'Калькулятор малоизвестных МФО {год} без отказа на карту',
    subtitle:
      'Калькулятор малоизвестных МФО {год} без отказа помогает рассчитать проценты и выбрать предложения с высокой вероятностью одобрения. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги на карту от надежных, но малоизвестных организаций!',
  },
  '/zaimy/mfo-bez-proverki-kreditnoy-istorii/': {
    title: 'Калькулятор МФО, которые не проверяют кредитную историю',
    subtitle:
      'Калькулятор МФО, которые не проверяют кредитную историю, поможет рассчитать проценты и выбрать предложения, доступные без проверки рейтинга. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги без отказа!',
  },
  '/zaimy/mgnovennye/': {
    title: 'Калькулятор мгновенных займов',
    subtitle:
      'Калькулятор мгновенных займов позволяет рассчитать проценты и выбрать предложения с моментальной выдачей. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги на карту за считаные минуты!',
  },
  '/zaimy/mikrokredit-na-kartu-onlayn/': {
    title: 'Калькулятор микрокредитов на карту онлайн',
    subtitle:
      'Калькулятор микрокредитов на карту онлайн помогает рассчитать проценты и выбрать выгодные условия для небольших сумм. Доступно {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги на карту быстро и удобно!',
  },
  '/zaimy/mkk/': {
    title: 'Калькулятор МКК онлайн на карту',
    subtitle:
      'Калькулятор МКК онлайн на карту позволяет рассчитать проценты и выбрать предложения от микрокредитных компаний. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги на карту без лишних проверок!',
  },
  '/zaimy/moskva/': {
    title: 'Калькулятор займов в Москве',
    subtitle:
      'Калькулятор займов в Москве помогает рассчитать проценты и выбрать предложения, доступные жителям столицы. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Найдите лучшие условия прямо в вашем городе!',
  },
  '/zaimy/na-1-mesjac/': {
    title: 'Калькулятор займов на месяц',
    subtitle:
      'Калькулятор займов на месяц позволяет рассчитать проценты и выбрать подходящие предложения для краткосрочного займа. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Решите финансовые вопросы быстро и выгодно!',
  },
  '/zaimy/na-2-goda/': {
    title: 'Калькулятор займов на 2 года',
    subtitle:
      'Калькулятор займов на 2 года позволяет рассчитать проценты и выбрать условия для долгосрочного займа. Доступно {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги на длительный срок!',
  },
  '/zaimy/na-2-mesjaca/': {
    title: 'Калькулятор займов на 2 месяца',
    subtitle:
      'Калькулятор займов на 2 месяца помогает рассчитать проценты и выбрать предложения для займа на короткий срок. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Быстро решите финансовые вопросы!',
  },
  '/zaimy/na-3-mesjaca/': {
    title: 'Калькулятор займов на 3 месяца',
    subtitle:
      'Калькулятор займов на 3 месяца позволяет рассчитать переплату и выбрать выгодные предложения. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги быстро и удобно!',
  },
  '/zaimy/na-4-mesjaca/': {
    title: 'Калькулятор займов на 4 месяца',
    subtitle:
      'Калькулятор займов на 4 месяца поможет рассчитать проценты и выбрать предложения для среднего срока займа. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Решите свои финансовые задачи с комфортом!',
  },
  '/zaimy/na-5-let/': {
    title: 'Калькулятор займов на 5 лет',
    subtitle:
      'Калькулятор займов на 5 лет помогает рассчитать проценты и выбрать оптимальные предложения для долгосрочных займов. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги на длительный срок с выгодными условиями!',
  },
  '/zaimy/na-60-dnej/': {
    title: 'Калькулятор займов на 60 дней',
    subtitle:
      'Калькулятор займов на 60 дней позволяет рассчитать проценты и выбрать подходящие предложения для двухмесячного займа. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги без сложностей!',
  },
  '/zaimy/na-bankovskiy-schet/': {
    title: 'Калькулятор займов на банковский счет',
    subtitle:
      'Калькулятор займов на банковский счет помогает рассчитать проценты и выбрать предложения с переводом напрямую на счет. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги быстро и надежно!',
  },
  '/zaimy/na-chuzhuju-kartu/': {
    title: 'Калькулятор займов на чужую карту',
    subtitle:
      'Калькулятор займов на чужую карту позволяет рассчитать проценты и выбрать подходящие предложения для перевода средств. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги с переводом на указанную карту!',
  },
  '/zaimy/na-contact/': {
    title: 'Калькулятор займов через систему Контакт',
    subtitle:
      'Калькулятор займов через систему Контакт позволяет рассчитать проценты и выбрать предложения с выдачей через денежные переводы. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги наличными через систему Контакт!',
  },
  '/zaimy/na-debetovuju-kartu/': {
    title: 'Калькулятор займов на дебетовую карту',
    subtitle:
      'Калькулятор займов на дебетовую карту помогает рассчитать проценты и выбрать предложения с переводом на вашу карту. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги быстро и без лишних проверок!',
  },
  '/zaimy/na-dom/': {
    title: 'Калькулятор займов с доставкой на дом',
    subtitle:
      'Калькулятор займов с доставкой на дом помогает рассчитать проценты и выбрать предложения с выдачей наличных прямо к вам домой. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Удобно и без посещения офиса!',
  },
  '/zaimy/na-elektronnyy-koshelek/': {
    title: 'Калькулятор займов на электронный кошелек',
    subtitle:
      'Калькулятор займов на электронный кошелек позволяет рассчитать проценты и выбрать подходящие предложения. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги на ваш электронный кошелек быстро и удобно!',
  },
  '/zaimy/na-god-12-mesyacev/': {
    title: 'Калькулятор займов на год',
    subtitle:
      'Калькулятор займов на год помогает рассчитать проценты и выбрать предложения с длительным сроком погашения. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Идеально для крупных покупок или долгосрочных целей!',
  },
  '/zaimy/na-kartu-bez-otkazov/': {
    title: 'Калькулятор займов без отказа на карту',
    subtitle:
      'Калькулятор займов без отказа на карту помогает рассчитать проценты и выбрать предложения с высокой вероятностью одобрения. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги на карту быстро и без отказов!',
  },
  '/zaimy/na-kartu-bez-proverki-kreditnoy-istorii/': {
    title: 'Калькулятор займов без проверки кредитной истории',
    subtitle:
      'Калькулятор займов без проверки кредитной истории позволяет рассчитать проценты и выбрать предложения, доступные без проверки рейтинга. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги без отказов и сложностей!',
  },
  '/zaimy/na-kartu-bez-proverok-i-zvonkov/': {
    title: 'Калькулятор займов без звонков и проверок',
    subtitle:
      'Калькулятор займов без звонков и проверок помогает рассчитать проценты и выбрать предложения, не требующие звонков или дополнительных проверок. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги максимально быстро и просто!',
  },
  '/zaimy/na-kartu-do-zarplaty/': {
    title: 'Калькулятор займов до зарплаты',
    subtitle:
      'Калькулятор займов до зарплаты позволяет рассчитать проценты и выбрать предложения с коротким сроком погашения. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги, когда они вам особенно нужны!',
  },
  '/zaimy/na-kartu-kukuruza-online/': {
    title: 'Калькулятор займов на карту Кукуруза',
    subtitle:
      'Калькулятор займов на карту Кукуруза помогает рассчитать проценты и выбрать предложения с быстрым переводом. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги на вашу карту Кукуруза без лишних формальностей!',
  },
  '/zaimy/na-kartu-mastercard/': {
    title: 'Калькулятор займов на карту Мастеркард',
    subtitle:
      'Калькулятор займов на карту Мастеркард позволяет рассчитать проценты и выбрать предложения с переводом на вашу карту. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Быстрое получение денег на карту Мастеркард!',
  },
  '/zaimy/na-kartu-mir/': {
    title: 'Калькулятор займов на карту Мир',
    subtitle:
      'Калькулятор займов на карту Мир помогает рассчитать проценты и выбрать предложения с переводом на карту национальной платежной системы. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги быстро и удобно!',
  },
  '/zaimy/na-kartu-momentum/': {
    title: 'Калькулятор займов на карту Моментум',
    subtitle:
      'Калькулятор займов на карту Моментум позволяет рассчитать проценты и выбрать предложения с переводом на вашу карту. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги на карту Моментум быстро и без лишних проверок!',
  },
  '/zaimy/na-kartu-sberbanka-do-zarplaty/': {
    title: 'Калькулятор займов до зарплаты на карту Сбербанка',
    subtitle:
      'Калькулятор займов до зарплаты на карту Сбербанка помогает рассчитать проценты и выбрать предложения с быстрым переводом. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги на карту Сбербанка без ожидания!',
  },
  '/zaimy/na-kartu-s-nulevym-balansom/': {
    title: 'Калькулятор займов на карту с нулевым балансом',
    subtitle:
      'Калькулятор займов на карту с нулевым балансом позволяет рассчитать проценты и выбрать предложения, доступные без требований к минимальному остатку. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги, даже если баланс вашей карты равен нулю!',
  },
  '/zaimy/na-kartu-tinkoff/': {
    title: 'Калькулятор займов на карту Тинькофф',
    subtitle:
      'Калькулятор займов на карту Тинькофф помогает рассчитать проценты и выбрать предложения с быстрым переводом. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги на вашу карту Тинькофф быстро и удобно!',
  },
  '/zaimy/na-kartu-visa/': {
    title: 'Калькулятор займов на карту Виза',
    subtitle:
      'Калькулятор займов на карту Виза позволяет рассчитать проценты и выбрать предложения с переводом на вашу карту Visa. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги быстро и без лишних проверок!',
  },
  '/zaimy/na-kartu-vtb/': {
    title: 'Калькулятор займов на карту ВТБ',
    subtitle:
      'Калькулятор займов на карту ВТБ помогает рассчитать проценты и выбрать выгодные предложения с переводом на вашу карту. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги на карту ВТБ быстро и удобно!',
  },
  '/zaimy/na-kartu-za-5-minut/': {
    title: 'Калькулятор займов за 5 минут',
    subtitle:
      'Калькулятор займов за 5 минут помогает рассчитать проценты и выбрать предложения с моментальной выдачей. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги на карту всего за 5 минут!',
  },
  '/zaimy/na-kivi-bez-karty/': {
    title: 'Калькулятор займов на Киви без карты',
    subtitle:
      'Калькулятор займов на Киви без карты позволяет рассчитать проценты и выбрать предложения с переводом на ваш кошелек без необходимости привязывать карту. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги на Киви моментально!',
  },
  '/zaimy/na-kivi-bez-otkazov/': {
    title: 'Калькулятор займов на Киви кошелек без отказов',
    subtitle:
      'Калькулятор займов на Киви кошелек без отказов позволяет рассчитать проценты и выбрать предложения с высокой вероятностью одобрения. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги на Киви без отказа!',
  },
  '/zaimy/na-kivi-bez-pasporta/': {
    title: 'Калькулятор займов на Киви без паспорта',
    subtitle:
      'Калькулятор займов на Киви без паспорта помогает рассчитать проценты и выбрать предложения, не требующие загрузки документов. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги на Киви без лишних проверок!',
  },
  '/zaimy/na-kivi-bez-procentov/': {
    title: 'Калькулятор займов на Киви кошелек без процентов',
    subtitle:
      'Калькулятор займов на Киви кошелек без процентов помогает выбрать предложения с нулевой переплатой. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — 0%. Получите первый займ на Киви без переплаты!',
  },
  '/zaimy/na-kivi-bez-zvonkov/': {
    title: 'Калькулятор займов на Киви без звонков',
    subtitle:
      'Калькулятор займов на Киви без звонков позволяет рассчитать проценты и выбрать предложения без необходимости общения с представителями МФО. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги на Киви быстро и без лишних звонков!',
  },
  '/zaimy/na-kivi-pensioneram/': {
    title: 'Калькулятор займов на Киви пенсионерам',
    subtitle:
      'Калькулятор займов на Киви пенсионерам помогает рассчитать проценты и выбрать предложения, доступные пожилым заемщикам. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги на Киви-кошелек без сложностей!',
  },
  '/zaimy/na-kivi-po-pasportu/': {
    title: 'Калькулятор займов по паспорту на Киви',
    subtitle:
      'Калькулятор займов по паспорту на Киви помогает рассчитать проценты и выбрать предложения, где достаточно одного документа. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги на Киви без лишних формальностей!',
  },
  '/zaimy/na-kivi-s-18-let/': {
    title: 'Калькулятор займов на Киви с 18 лет',
    subtitle:
      'Калькулятор займов на Киви с 18 лет позволяет рассчитать проценты и выбрать предложения для молодых заемщиков. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги на Киви, даже если вам всего 18 лет!',
  },
  '/zaimy/na-kivi-virtualnuju-kartu/': {
    title: 'Калькулятор займов на виртуальную карту Киви',
    subtitle:
      'Калькулятор займов на виртуальную карту Киви позволяет рассчитать проценты и выбрать предложения с переводом на вашу виртуальную карту. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги на виртуальную карту моментально!',
  },
  '/zaimy/na-kreditnuju-kartu/': {
    title: 'Калькулятор займов на кредитную карту',
    subtitle:
      'Калькулятор займов на кредитную карту помогает рассчитать проценты и выбрать предложения с переводом на вашу карту. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги на кредитную карту быстро и удобно!',
  },
  '/zaimy/na-neimennuyu-kartu/': {
    title: 'Калькулятор займов на неименную карту',
    subtitle:
      'Калькулятор займов на неименную карту помогает рассчитать проценты и выбрать предложения, доступные для карт без имени владельца. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги на карту без лишних проверок!',
  },
  '/zaimy/na-noviy-god/': {
    title: 'Калькулятор займов на Новый год',
    subtitle:
      'Калькулятор займов на Новый год помогает рассчитать проценты и выбрать предложения для праздничных покупок. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Создайте новогоднее настроение с финансовой поддержкой!',
  },
  '/zaimy/na-polgoda-6-mesyacev/': {
    title: 'Калькулятор займов на полгода',
    subtitle:
      'Калькулятор займов на полгода помогает рассчитать проценты и выбрать предложения с комфортным сроком погашения. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги на удобных условиях!',
  },
  '/zaimy/na-sberknizhku/': {
    title: 'Калькулятор займов на сберкнижку',
    subtitle:
      'Калькулятор займов на сберкнижку позволяет рассчитать проценты и выбрать предложения с переводом средств на ваш банковский счет, привязанный к сберкнижке. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Удобный способ получить деньги без карты!',
  },
  '/zaimy/na-telefon/': {
    title: 'Калькулятор займов на телефон',
    subtitle:
      'Калькулятор займов на телефон помогает рассчитать проценты и выбрать предложения с выдачей денег, которые можно потратить на покупку телефона. Доступно {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги быстро и без лишних документов!',
  },
  '/zaimy/na-virtualnuyu-kartu/': {
    title: 'Калькулятор займов на виртуальную карту',
    subtitle:
      'Калькулятор займов на виртуальную карту позволяет рассчитать проценты и выбрать предложения с мгновенным переводом на вашу виртуальную карту. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Удобное решение для цифровых покупок!',
  },
  '/zaimy/na-yandex/': {
    title: 'Калькулятор займов на Яндекс Деньги',
    subtitle:
      'Калькулятор займов на Яндекс Деньги помогает рассчитать проценты и выбрать предложения с переводом на электронный кошелек Яндекс. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги на Яндекс быстро и удобно!',
  },
  '/zaimy/na-yandex-bez-privjazki-karty/': {
    title: 'Калькулятор займов на Яндекс Деньги без привязки карты',
    subtitle:
      'Калькулятор займов на Яндекс Деньги без привязки карты позволяет рассчитать проценты и выбрать предложения, не требующие привязки банковской карты. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги быстро и просто!',
  },
  '/zaimy/na-zolotuyu-koronu/': {
    title: 'Калькулятор займов через Золотую Корону',
    subtitle:
      'Калькулятор займов через Золотую Корону помогает рассчитать проценты и выбрать предложения с переводом через эту систему денежных переводов. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги наличными через Золотую Корону!',
  },
  '/zaimy/ne-vyhodja-iz-doma/': {
    title: 'Калькулятор займов не выходя из дома',
    subtitle:
      'Калькулятор займов не выходя из дома позволяет рассчитать проценты и выбрать предложения, доступные онлайн. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги, не покидая своего дома!',
  },
  '/zaimy/nochju/': {
    title: 'Калькулятор займов ночью онлайн',
    subtitle:
      'Калькулятор займов ночью онлайн позволяет рассчитать проценты и выбрать предложения с круглосуточной выдачей. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги на карту в любое время суток!',
  },
  '/zaimy/novye-mfo/': {
    title: 'Калькулятор новых МФО {год} года',
    subtitle:
      'Калькулятор новых МФО {год} года помогает рассчитать проценты и выбрать актуальные предложения от новых микрофинансовых организаций. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Воспользуйтесь современными условиями займа!',
  },
  '/zaimy/pensioneram/': {
    title: 'Калькулятор займов для пенсионеров',
    subtitle:
      'Калькулятор займов для пенсионеров позволяет рассчитать проценты и выбрать предложения, доступные для пожилых заемщиков. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги на карту без сложностей!',
  },
  '/zaimy/perevodom/': {
    title: 'Калькулятор займов денежным переводом',
    subtitle:
      'Калькулятор займов денежным переводом позволяет рассчитать проценты и выбрать предложения с выдачей средств через денежные переводы. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги без карты и счета!',
  },
  '/zaimy/pod-0-protsentov/': {
    title: 'Калькулятор займов под 0 процентов на карту',
    subtitle:
      'Калькулятор займов под 0 процентов на карту позволяет рассчитать условия первого займа без переплаты. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — 0%. Получите деньги на карту без процентов!',
  },
  '/zaimy/pod-pts/': {
    title: 'Калькулятор займов под залог ПТС',
    subtitle:
      'Калькулятор займов под залог ПТС помогает рассчитать проценты и выбрать предложения с предоставлением автомобиля в залог. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите крупные суммы под залог вашего авто!',
  },
  '/zaimy/pod-pts-avtolombardy/': {
    title: 'Калькулятор автоломбардов под залог ПТС',
    subtitle:
      'Калькулятор автоломбардов под залог ПТС помогает рассчитать проценты и выбрать предложения от проверенных автоломбардов. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги под залог авто быстро и безопасно!',
  },
  '/zaimy/pod-pts-bez-avto/': {
    title: 'Калькулятор займов под залог ПТС без осмотра авто',
    subtitle:
      'Калькулятор займов под залог ПТС без осмотра авто позволяет рассчитать проценты и выбрать предложения, не требующие проверки машины. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги без дополнительных требований!',
  },
  '/zaimy/pod-pts-po-doverennosti/': {
    title: 'Калькулятор займов под залог ПТС по доверенности',
    subtitle:
      'Калькулятор займов под залог ПТС по доверенности помогает рассчитать проценты и выбрать предложения, доступные для оформления через доверенное лицо. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги на выгодных условиях!',
  },
  '/zaimy/pod-raspisku/': {
    title: 'Калькулятор займов под расписку',
    subtitle:
      'Калькулятор займов под расписку позволяет рассчитать проценты и выбрать предложения, оформляемые на основании письменного обязательства. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги быстро и без лишних документов!',
  },
  '/zaimy/pod-zalog-avtomobilya-s-pravom-polzovaniya/': {
    title: 'Калькулятор займов под залог авто с правом пользования',
    subtitle:
      'Калькулятор займов под залог авто с правом пользования помогает рассчитать проценты и выбрать предложения, позволяющие вам продолжать пользоваться автомобилем. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Удобный способ получить крупный займ!',
  },
  '/zaimy/pod-zalog-documentov/': {
    title: 'Калькулятор денег под залог документов',
    subtitle:
      'Калькулятор денег под залог документов позволяет рассчитать проценты и выбрать предложения, где в качестве обеспечения используются документы. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги просто и без сложностей!',
  },
  '/zaimy/pod-zalog-gruzovogo-avtomobilja/': {
    title: 'Калькулятор займов под ПТС грузового автомобиля',
    subtitle:
      'Калькулятор займов под ПТС грузового автомобиля позволяет рассчитать проценты и выбрать предложения, доступные под залог грузового транспорта. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги под залог вашего грузовика!',
  },
  '/zaimy/pod-zalog-motocykla/': {
    title: 'Калькулятор займов под залог ПТС мотоцикла',
    subtitle:
      'Калькулятор займов под залог ПТС мотоцикла помогает рассчитать проценты и выбрать предложения, доступные для владельцев мотоциклов. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Быстрое решение для любителей двухколесного транспорта!',
  },
  '/zaimy/pod-zalog-spectehniki/': {
    title: 'Калькулятор займов под залог спецтехники',
    subtitle:
      'Калькулятор займов под залог спецтехники позволяет рассчитать проценты и выбрать предложения для владельцев строительной и сельскохозяйственной техники. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги под залог вашей техники!',
  },
  '/zaimy/po-pasportu/': {
    title: 'Калькулятор займов по паспорту',
    subtitle:
      'Калькулятор займов по паспорту позволяет рассчитать проценты и выбрать предложения, где нужен только паспорт для оформления. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги быстро и без дополнительных документов!',
  },
  '/zaimy/po-sms/': {
    title: 'Калькулятор СМС-займов',
    subtitle:
      'Калькулятор СМС-займов помогает рассчитать проценты и выбрать предложения, доступные через отправку СМС-заявки. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги через простое сообщение!',
  },
  '/zaimy/po-telefonu/': {
    title: 'Калькулятор займов по телефону',
    subtitle:
      'Калькулятор займов по телефону позволяет рассчитать проценты и выбрать предложения, которые можно оформить через звонок. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Быстро и удобно для всех!',
  },
  '/zaimy/po-vsej-rossii/': {
    title: 'Калькулятор займов на карту по всей России',
    subtitle:
      'Калькулятор займов на карту по всей России помогает рассчитать проценты и выбрать предложения, доступные жителям любого региона. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги без ограничений по географии!',
  },
  '/zaimy/proverennyye-zaymy-do-30-000/': {
    title: 'Калькулятор проверенных займов на карту',
    subtitle:
      'Калькулятор проверенных займов на карту позволяет рассчитать проценты и выбрать надежные предложения от проверенных МФО. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Надежный способ получить деньги на карту!',
  },
  '/zaimy/refinansirovanie/': {
    title: 'Калькулятор рефинансирования микрозаймов и займов онлайн',
    subtitle:
      'Калькулятор рефинансирования микрозаймов и займов онлайн позволяет рассчитать экономию и выбрать предложения для погашения старых долгов. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Оптимизируйте свои финансы!',
  },
  '/zaimy/robot/': {
    title: 'Калькулятор роботов займов на карту',
    subtitle:
      'Калькулятор роботов займов на карту помогает рассчитать проценты и выбрать предложения, оформляемые автоматически через специальные сервисы. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Удобный способ получить деньги мгновенно!',
  },
  '/zaimy/s-19-let-na-kartu/': {
    title: 'Калькулятор займов с 19 лет на карту',
    subtitle:
      'Калькулятор займов с 19 лет на карту помогает рассчитать проценты и выбрать предложения для молодых заемщиков. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Идеально для тех, кто только начинает финансовую самостоятельность!',
  },
  '/zaimy/s-21-goda/': {
    title: 'Калькулятор займов с 21 года',
    subtitle:
      'Калькулятор займов с 21 года помогает рассчитать проценты и выбрать предложения для заемщиков, достигших 21 года. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Удобный способ получить деньги без сложностей!',
  },
  '/zaimy/s-avtomaticheskim-odobreniem-na-kartu-online/': {
    title: 'Калькулятор займов автоматом на карту',
    subtitle:
      'Калькулятор займов автоматом на карту позволяет рассчитать проценты и выбрать предложения с автоматической выдачей. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Быстрый и удобный способ получить деньги!',
  },
  '/zaimy/s-chernym-spiskom/': {
    title: 'Калькулятор займов с черным списком',
    subtitle:
      'Калькулятор займов с черным списком позволяет рассчитать проценты и выбрать предложения, доступные заемщикам с отрицательной кредитной историей. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Получите деньги даже при наличии долгов!',
  },
  '/zaimy/servis/': {
    title: 'Калькулятор подбора займов на карту',
    subtitle:
      'Калькулятор подбора займов на карту помогает рассчитать проценты и подобрать лучшие предложения под ваши нужды. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Найдите оптимальные условия и получите деньги быстро!',
  },
  '/zaimy/s-ezhemesyachnym-platezhom/': {
    title: 'Калькулятор займов с ежемесячным платежом',
    subtitle:
      'Калькулятор займов с ежемесячным платежом позволяет рассчитать график погашения и выбрать подходящие предложения. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Удобный способ взять деньги с комфортным погашением!',
  },
  '/zaimy/s-ljuboy-ki/': {
    title: 'Калькулятор займов с любой кредитной историей',
    subtitle:
      'Калькулятор займов с любой кредитной историей помогает рассчитать проценты и выбрать предложения, доступные даже заемщикам с низким рейтингом. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги без отказов!',
  },
  '/zaimy/srazu/': {
    title: 'Калькулятор займов сразу на карту',
    subtitle:
      'Калькулятор займов сразу на карту позволяет рассчитать проценты и выбрать предложения с моментальной выдачей. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги прямо сейчас!',
  },
  '/zaimy/srochno-100/': {
    title: 'Калькулятор займов от 100 рублей',
    subtitle:
      'Калькулятор займов от 100 рублей помогает рассчитать проценты и выбрать микрозаймы на небольшие суммы. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги на любую нужду!',
  },
  '/zaimy/srochno-1000/': {
    title: 'Калькулятор займов от 1000 рублей',
    subtitle:
      'Калькулятор займов от 1000 рублей позволяет рассчитать проценты и выбрать предложения с минимальной суммой займа. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Легкое решение финансовых вопросов!',
  },
  '/zaimy/srochno-10000/': {
    title: 'Калькулятор займов до 10000 рублей',
    subtitle:
      'Калькулятор займов до 10000 рублей помогает рассчитать проценты и выбрать предложения для небольших сумм. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги на карту за считаные минуты!',
  },
  '/zaimy/srochno-100000/': {
    title: 'Калькулятор займов до 100000 рублей',
    subtitle:
      'Калькулятор займов до 100000 рублей позволяет рассчитать проценты и выбрать предложения для средних сумм. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Идеально для решения бытовых задач и мелких покупок!',
  },
  '/zaimy/srochno-1000000/': {
    title: 'Калькулятор займов до 1000000 рублей',
    subtitle:
      'Калькулятор займов до 1000000 рублей помогает рассчитать проценты и выбрать предложения с крупными суммами. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги на реализацию серьезных проектов!',
  },
  '/zaimy/srochno-12000/': {
    title: 'Калькулятор займов до 12000 рублей',
    subtitle:
      'Калькулятор займов до 12000 рублей позволяет рассчитать проценты и выбрать предложения для небольших и средних сумм. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Решите свои финансовые вопросы быстро!',
  },
  '/zaimy/srochno-1500/': {
    title: 'Калькулятор займов на 1500 рублей',
    subtitle:
      'Калькулятор займов на 1500 рублей позволяет рассчитать проценты и выбрать предложения для конкретной суммы. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Удобный способ взять деньги на небольшие расходы!',
  },
  '/zaimy/srochno-15000/': {
    title: 'Калькулятор займов до 15000 рублей',
    subtitle:
      'Калькулятор займов до 15000 рублей помогает рассчитать проценты и выбрать предложения с доступными условиями. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Быстрое решение для мелких расходов!',
  },
  '/zaimy/srochno-150000/': {
    title: 'Калькулятор займов до 150000 рублей',
    subtitle:
      'Калькулятор займов до 150000 рублей позволяет рассчитать проценты и выбрать предложения для средних сумм. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Удобное решение для повседневных и крупных покупок!',
  },
  '/zaimy/srochno-2000/': {
    title: 'Калькулятор займов на 2000 рублей',
    subtitle:
      'Калькулятор займов на 2000 рублей помогает рассчитать проценты и выбрать предложения для указанной суммы. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Получите деньги на мелкие расходы!',
  },
  '/zaimy/srochno-20000/': {
    title: 'Калькулятор займов до 20000 рублей',
    subtitle:
      'Калькулятор займов до 20000 рублей позволяет рассчитать проценты и выбрать выгодные условия для средней суммы. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Быстрое решение финансовых задач!',
  },
  '/zaimy/srochno-200000/': {
    title: 'Калькулятор займов до 200000 рублей',
    subtitle:
      'Калькулятор займов до 200000 рублей помогает рассчитать проценты и выбрать предложения с доступными условиями для крупных сумм. На выбор — {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка — от {минимальная_ставка}% в день. Отличный выбор для серьезных покупок!',
  },
  '/zaimy/srochno-25000/': {
    title: 'Калькулятор займов до 25000 рублей',
    subtitle:
      'Калькулятор займов до 25000 рублей позволяет рассчитать проценты и выбрать предложения с выгодными условиями. Доступны {количество_предложений} от {количество_организаций_где} МФО, минимальная ставка начинается от {минимальная_ставка}% в день. Идеально для мелких и средних расходов!',
  },
};
