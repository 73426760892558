import type { CSSProperties, FC } from 'react';
import { memo, useCallback } from 'react';

import { Button } from '@sravni/react-design-system';

import { PRODUCT_LIST_ANCHOR } from '@src/constants/common';
import { sendShowProductListClickEvent } from '@src/helpers/analyticsEvents/calculator';
import { scrollToElementById } from '@src/helpers/common';
import { CurrencySymbol } from 'shared/config/internalization';

import type { CalculatorState } from '../../../types';
import { useCalculations } from '../lib/useCalculations';

import styles from './Calculations.module.scss';
import { calculationsTexts } from './Calculations.text';

interface Props {
  values: CalculatorState;
  currencySymbol?: string;
}

export const Calculations: FC<Props> = memo(({ values, currencySymbol }) => {
  const { progressBarLegends, calculations } = useCalculations(values);

  const handleShowOffersClick = useCallback(() => {
    scrollToElementById(PRODUCT_LIST_ANCHOR);
    sendShowProductListClickEvent();
  }, []);

  return (
    <div className={styles.container}>
      {progressBarLegends.length > 0 && (
        <div className={styles.row}>
          <div className={styles.legend}>
            {progressBarLegends.map((item) => (
              <div
                key={item.legendName}
                className={styles.legend_item}
                style={{ '--color': item.color } as CSSProperties}
              >
                {item.legendName}
              </div>
            ))}
          </div>

          <div className={styles.legend_bar}>
            {progressBarLegends.map((item) => (
              <div
                key={item.name}
                className={styles.legend_bar_item}
                style={{ '--color': item.color, '--width': `${item.width}%` } as CSSProperties}
              />
            ))}
          </div>
        </div>
      )}

      <div className={styles.row}>
        {calculations.length > 0 ? (
          <div className={styles.calculation_block}>
            {calculations.map((item) => (
              <div key={item.name} className={styles.calculation_item}>
                <div className={styles.calculation_item_name}>{item.name}</div>
                <div className={styles.calculation_item_value} style={{ color: item.color }}>
                  {item.value?.toLocaleString('ru')} {currencySymbol}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className={styles.empty_block}>{calculationsTexts.specifyNecessaryParameters}</div>
        )}

        <Button size={60} block className={styles.button} variant="primary" onClick={handleShowOffersClick}>
          {calculationsTexts.viewOffers}
        </Button>
      </div>
    </div>
  );
});

Calculations.displayName = 'Calculations';

Calculations.defaultProps = {
  currencySymbol: CurrencySymbol.RUB,
};
