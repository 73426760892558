import { useMemo } from 'react';

import type { CalculatorState } from '../../../types';

import { buildCalculations } from './buildCalculations';

export const useCalculations = (calculatorState: CalculatorState) =>
  useMemo(() => {
    const calculations = buildCalculations(calculatorState).filter((item) => item.value);

    return {
      progressBarLegends: calculations.slice(0, -1),
      calculations,
    };
  }, [calculatorState]);
