import { getYear } from 'date-fns';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

import pluralize from '@sravni/utils/lib/pluralize';

import { useSelectMicroCreditsMetadata } from '@src/reducers/microcredits';
import { getCountryByUrl } from 'shared/lib/getCountryByUrl';
import { getMinMicrocreditRateByCountry } from 'shared/lib/getMinMicrocreditRateByCountry';

import {
  CALCULATOR_HEADINGS_BY_ROUTE,
  MIN_RATE_PLACEHOLDER,
  OFFERS_COUNT_PLACEHOLDER,
  ORGANIZATIONS_COUNT_PLACEHOLDER,
  YEAR_PLACEHOLDER,
} from '../config';

export const useGetCalculatorHeadingByRoute = () => {
  const { minRate, counts } = useSelectMicroCreditsMetadata() || {};
  const { asPath } = useRouter();

  const pathWithoutQuery = asPath.split('?')[0];

  return useMemo(() => {
    const headers = CALCULATOR_HEADINGS_BY_ROUTE[pathWithoutQuery];

    if (pathWithoutQuery && headers) {
      const { title, subtitle } = headers;

      const minRateByCountry = getMinMicrocreditRateByCountry(getCountryByUrl(pathWithoutQuery));

      const offersCount = counts?.offers || 0;
      const organizationsCount = counts?.organizations || 0;
      /**
       * todo: @andrei.shchemelinin сказал, что процент в Казахстане начинается с 0.01%,
       * а исходя из данных с бэка процент начинается с 0.
       * Поэтому мы берем минимальный процент, исходя из страны.
       */
      const _minRate = Math.max(minRate?.rate || minRateByCountry);

      return {
        title: formatTitle(title),
        subtitle: formatSubtitle(subtitle, offersCount, organizationsCount, _minRate),
      };
    }
    return null;
  }, [pathWithoutQuery, counts, minRate?.rate]);
};

function formatSubtitle(subtitle: string, offers: number, organizations: number, minRate: number) {
  let _subtitle = subtitle;

  _subtitle = subtitle.replace(
    OFFERS_COUNT_PLACEHOLDER,
    `${offers} ${pluralize(offers, 'предложение', 'предложения', 'предложений')}`,
  );

  if (_subtitle.includes(ORGANIZATIONS_COUNT_PLACEHOLDER)) {
    _subtitle = _subtitle.replace(ORGANIZATIONS_COUNT_PLACEHOLDER, organizations.toString());
  }

  if (_subtitle.includes(MIN_RATE_PLACEHOLDER)) {
    _subtitle = _subtitle.replace(MIN_RATE_PLACEHOLDER, minRate.toString());
  }

  if (_subtitle.includes(YEAR_PLACEHOLDER)) {
    _subtitle = _subtitle.replace(YEAR_PLACEHOLDER, getYear(new Date()).toString());
  }

  return _subtitle;
}

function formatTitle(subtitle: string) {
  let title = subtitle;

  if (title.includes(YEAR_PLACEHOLDER)) {
    title = title.replace(YEAR_PLACEHOLDER, getYear(new Date()).toString());
  }

  return title;
}
