import { EVENT_CATEGORY } from '@src/helpers/analyticsEvents/constants';
import { makeMainGEvent, pushEvent } from '@src/utils/analytics';

export const sendShowProductListClickEvent = () => {
  pushEvent(
    makeMainGEvent({
      eventCategory: EVENT_CATEGORY,
      eventAction: 'Калькулятор процентов|Смотреть предложения',
      eventLabel: undefined,
      eventValue: undefined,
    }),
  );
};

export const sendChangeInputValueEvent = (inputName: string, inputValue: string) => {
  pushEvent(
    makeMainGEvent({
      eventCategory: EVENT_CATEGORY,
      eventAction: `Калькулятор процентов|Фильтр|${inputName}`,
      eventLabel: inputValue,
      eventValue: undefined,
    }),
  );
};

export const sendAddPenaltyTermClickEvent = () => {
  pushEvent(
    makeMainGEvent({
      eventCategory: EVENT_CATEGORY,
      eventAction: 'Калькулятор процентов|Добавить просрочку',
      eventLabel: undefined,
      eventValue: undefined,
    }),
  );
};

export const sendChangePenaltyTermValueEvent = (_, inputValue: string) => {
  pushEvent(
    makeMainGEvent({
      eventCategory: EVENT_CATEGORY,
      eventAction: `Калькулятор процентов|Срок просрочки`,
      eventLabel: inputValue,
      eventValue: undefined,
    }),
  );
};
