import { RUSSIA_AMOUNT_FIELD_SETTINGS } from '@src/constants/amountSettings';
import { RUSSIA_RATE_FIELD_SETTINGS } from '@src/constants/rateSettings';
import { formatMoney } from '@src/helpers/formatMoney';
import { CurrencySymbol } from 'shared/config/internalization';

export const RU_AMOUNT_DEFAULT_PROPS = {
  label: 'Сумма займа',
  postfix: CurrencySymbol.RUB,
  min: 1000,
  max: RUSSIA_AMOUNT_FIELD_SETTINGS.max,
  helperText: `от 1 000 до ${formatMoney(RUSSIA_AMOUNT_FIELD_SETTINGS.max)}`,
};

export const RU_RATE_DEFAULT_PROPS = {
  label: 'Ставка',
  postfix: '%',
  min: RUSSIA_RATE_FIELD_SETTINGS.min,
  max: RUSSIA_RATE_FIELD_SETTINGS.max,
  helperText: 'от 0 до 0,8%',
};

export const TERM_PROPS = {
  label: 'На срок',
  min: 1,
  max: 365,
  helperText: 'от 1 до 365 дней',
};

export const PENALTY_TERM_PROPS = {
  label: 'Срок просрочки',
  min: 1,
  max: 365,
  helperText: 'от 1 до 365 дней',
};
