import type { FC } from 'react';
import { memo } from 'react';

import { Button, Divider, Icon, Typography } from '@sravni/react-design-system';
import { Plus } from '@sravni/react-icons';
import { useBoolean } from '@sravni/react-utils';

import { CustomTrash } from '@src/components/icons';
import { TermControl } from '@src/components/ui/TermControl';
import { FILTERS_NAMES } from '@src/constants/filters';
import { sendAddPenaltyTermClickEvent, sendChangePenaltyTermValueEvent } from '@src/helpers/analyticsEvents/calculator';
import { getDaysPluralize } from '@src/helpers/strings';
import { HelperText } from 'shared/ui/HelperText';

import type { CalculatorParams, CalculatorState } from '../../../../../types';
import { PENALTY_TERM_PROPS } from '../../CalculatorFields.config';

import styles from './Penalty.module.scss';
import { penaltyText } from './Penalty.text';

const { Text } = Typography;

interface Props {
  values: CalculatorState;
  onChange: (value: number | undefined, name: string) => void;
  onReset: (resetParams: CalculatorParams[]) => void;
}

export const Penalty: FC<Props> = memo(({ values, onChange, onReset }) => {
  const [showPenalty, setShowPenalty] = useBoolean(false);

  const handleClose = () => {
    onReset([FILTERS_NAMES.PENALTY_TERM]);
    setShowPenalty.off();
  };

  const handleAddPenaltyTermClick = () => {
    setShowPenalty.on();
    sendAddPenaltyTermClickEvent();
  };

  if (!showPenalty) {
    return (
      <div>
        <Button block onClick={handleAddPenaltyTermClick}>
          <Icon className={styles.plusIcon} size={20} icon={<Plus />} />
          {penaltyText.addPenalty}
        </Button>
      </div>
    );
  }

  const penaltyTerm = values[FILTERS_NAMES.PENALTY_TERM];
  const pluralizePenaltyTerm = getDaysPluralize(penaltyTerm);

  return (
    <div>
      <div className={styles.row}>
        <Text size={16} className="h-color-D60">
          {penaltyText.penalty}
        </Text>
        <Icon onClick={handleClose} className={styles.close_icon} icon={<CustomTrash color="#C11D1D" />} size={20} />
      </div>
      <div className={styles.row}>
        <div className={styles.inputWrapper}>
          <TermControl
            className={styles.term_control}
            name={FILTERS_NAMES.PENALTY_TERM}
            value={penaltyTerm}
            onChange={onChange}
            postfix={pluralizePenaltyTerm}
            analyticsEvent={sendChangePenaltyTermValueEvent}
            {...PENALTY_TERM_PROPS}
          />
          <HelperText text={PENALTY_TERM_PROPS.helperText} />
        </div>
      </div>

      <Divider className={styles.divider} size={8} />
    </div>
  );
});

Penalty.displayName = 'Penalty';
