import type { FC } from 'react';
import { memo, useCallback } from 'react';

import type { IFilters } from '@src/@types/microcredits';

import { useCalculatorOffers, useScrollToCalculator } from '../lib';
import type { CalculatorFieldProps, CalculatorParams } from '../types';

import { Calculations } from './Calculations';
import styles from './Calculator.module.scss';
import { CalculatorFields } from './CalculatorFields';
import { CalculatorHeading } from './CalculatorHeading';

interface Props {
  filters: Partial<IFilters>;
  rateProps?: CalculatorFieldProps;
  amountProps?: CalculatorFieldProps;
  title?: string;
  subtitle?: string;
  shouldScrollToCalculatorAfterChange?: boolean;
}

export const Calculator: FC<Props> = memo(
  ({ filters, shouldScrollToCalculatorAfterChange, rateProps, amountProps, ...headingProps }) => {
    const { values, setValue, resetValues } = useCalculatorOffers(filters);

    const handleSetValue = useCallback(
      (value: number | undefined, name: string) => {
        setValue(name as CalculatorParams, value);
      },
      [setValue],
    );

    const calculatorBlockRef = useScrollToCalculator({
      shouldBeScrolled: shouldScrollToCalculatorAfterChange,
      calculatorInputValues: values,
    });

    return (
      <div ref={calculatorBlockRef} className={styles.container}>
        <div className={styles.wrapper}>
          <CalculatorHeading {...headingProps} />

          <div className={styles.columnsWrapper}>
            <div className={styles.column}>
              <CalculatorFields
                amountProps={amountProps}
                rateProps={rateProps}
                values={values}
                onChange={handleSetValue}
                onReset={resetValues}
              />
            </div>

            <div className={styles.column}>
              <Calculations values={values} currencySymbol={amountProps?.postfix} />
            </div>
          </div>
        </div>
      </div>
    );
  },
);

Calculator.displayName = 'Calculator';
