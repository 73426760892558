/**
 * todo(1321): Убрать хардкод урлов страниц
 * @link https://sravni-corp.atlassian.net/browse/MFO-1321
 */

export const PAGES_WITH_CALCULATOR = [
  '/zaimy/top/',
  '/zaimy/24-chasa-onlayn/',
  '/zaimy/300-na-kivi/',
  '/zaimy/50000-na-kivi/',
  '/zaimy/bez-predoplaty/',
  '/zaimy/24-chasa-onlayn/',
  '/zaimy/300-na-kivi/',
  '/zaimy/50000-na-kivi/',
  '/zaimy/bez-predoplaty/',
  '/zaimy/bez-protsentov-na-30-dnej/',
  '/zaimy/bez-pts/',
  '/zaimy/bezrabotnym-na-kivi/',
  '/zaimy/bez-snilsa/',
  '/zaimy/bez-spisanija/',
  '/zaimy/bez-telefona/',
  '/zaimy/bez-ukazanija-raboti/',
  '/zaimy/bez-zaloga/',
  '/zaimy/bolshie-zaimy/',
  '/zaimy/bystriy-na-kartu/',
  '/zaimy/cherez-gosuslugi/',
  '/zaimy/srochno-250000/',
  '/zaimy/srochno-3000/',
  '/zaimy/srochno-30000/',
  '/zaimy/srochno-300000/',
  '/zaimy/srochno-35000/',
  '/zaimy/srochno-4000/',
  '/zaimy/srochno-40000/',
  '/zaimy/srochno-500/',
  '/zaimy/srochno-5000/',
  '/zaimy/srochno-50000/',
  '/zaimy/srochno-500000/',
  '/zaimy/srochno-6000/',
  '/zaimy/srochno-60000/',
  '/zaimy/srochno-7000/',
  '/zaimy/srochno-70000/',
  '/zaimy/srochno-8000/',
  '/zaimy/srochno-80000/',
  '/zaimy/srochno-9000/',
  '/zaimy/srochno-90000/',
  '/zaimy/studentam-na-kartu-bez-otkaza/',
  '/zaimy/uluchshenie-ki/',
  '/zaimy/v-dolg/',
  '/zaimy/v-dolg-na-yandex/',
  '/zaimy/voennosluzhashhim/',
  '/zaimy/vsem/',
  '/zaimy/za-chas/',
  '/zaimy/za-minutu/',
  '/zaimy/zayavka-vo-vse-mikrozaimy-srazu/',
  '/zaimy/na-kivi/',
  '/zaimy/bez-protsentov/',
  '/zaimy/bez-otkaza/',
  '/zaimy/bez-podtverzhdenija-lichnosti/',
  /**
   * todo: Был баг, тред - https://sravni-corp.slack.com/archives/C06FGK2UE77/p1735285626451789
   * Решили пока скрыть, после нг будем разбираться
   * @link https://sravni-corp.atlassian.net/browse/MFO-1408
   */
  // '/zaimy/na-kartu/',
  '/zaimy/bezrabotnym/',
  '/zaimy/bez-propiski-i-vremennoy-registracii/',
  '/zaimy/bez-poruchiteley-i-spravok/',
  '/zaimy/',
  '/zaimy/v-dolg-na-kartu/',
  '/zaimy/s-plokhoj-kreditnoj-istoriej/',
  '/zaimy/mfo-online/',
  '/zaimy/mini-onlajn/',
  '/zaimy/kratkosrochnye/',
  '/zaimy/bez-proverok/',
  '/zaimy/na-kartu-maestro/',
  '/zaimy/na-kartu-mgnovenno-kruglosutochno/',
  '/zaimy/na-kartu-sberbanka/',
  '/zaimy/na-kivi-s-plohoy-ki/',
  '/zaimy/novye/',
  '/zaimy/onlain/',
  '/zaimy/pod-nizkij-procent/',
  '/zaimy/pod-zalog-nedvizhimosti/',
  '/zaimy/na-kartu-s-18-let-online/',
  '/zaimy/momentalny/',
  '/zaimy/s-prosrochkami/',
  '/zaimy/nalichnymi/',
  '/zaimy/za-15-minut/',
  '/kz/zaimy/',
  '/kz/zaimy/predlozheniya/',
  '/zaimy/1000-na-kivi/',
  '/zaimy/2000-na-kivi/',
  '/zaimy/3000-na-kivi/',
  '/zaimy/5000-na-kivi/',
  '/zaimy/500-na-kivi/',
  '/zaimy/70-let/',
  '/zaimy/75-let/',
  '/zaimy/80-let/',
  '/zaimy/85-let/',
  '/zaimy/besplatno/',
  '/zaimy/bez-documentov/',
  '/zaimy/bez-elektronnoj-pochty/',
  '/zaimy/bez-foto/',
  '/zaimy/bez-karty/',
  '/zaimy/bez-kreditnoy-istorii/',
  '/zaimy/bez-obmana/',
  '/zaimy/bez-otkaza-100-procentov-odobreniya/',
  '/zaimy/bezotkazanye/',
  '/zaimy/bez-pasporta/',
  '/zaimy/bez-podpisok/',
  '/zaimy/bez-posrednikov/',
  '/zaimy/dengi-v-dolg-bez-proverki-ki/',
  '/zaimy/dengi-v-dolg-na-kivi/',
  '/zaimy/dengi-v-dolg-v-20-let/',
  '/zaimy/dengi-v-rassrochku-bez-procentov/',
  '/zaimy/dlja-pogashenija/',
  '/zaimy/dlya-grazhdan-sng/',
  '/zaimy/dolg-na-kartu-sberbanka/',
  '/zaimy/dolgosrochnye/',
  '/zaimy/dolzhnikam/',
  '/zaimy/ehkspress-na-kartu/',
  '/zaimy/inostrancam/',
  '/zaimy/kalkuljator-microzaimov/',
  '/zaimy/kivi-yandex/',
  '/zaimy/kruglosutochno/',
  '/zaimy/maloizvestnye-mfo/',
  '/zaimy/mfo-bez-proverki-kreditnoy-istorii/',
  '/zaimy/mgnovennye/',
  '/zaimy/mikrokredit-na-kartu-onlayn/',
  '/zaimy/mkk/',
  '/zaimy/moskva/',
  '/zaimy/na-1-mesjac/',
  '/zaimy/na-2-goda/',
  '/zaimy/na-2-mesjaca/',
  '/zaimy/na-3-mesjaca/',
  '/zaimy/na-4-mesjaca/',
  '/zaimy/na-5-let/',
  '/zaimy/na-60-dnej/',
  '/zaimy/na-bankovskiy-schet/',
  '/zaimy/na-chuzhuju-kartu/',
  '/zaimy/na-contact/',
  '/zaimy/na-debetovuju-kartu/',
  '/zaimy/na-dom/',
  '/zaimy/na-elektronnyy-koshelek/',
  '/zaimy/na-god-12-mesyacev/',
  '/zaimy/na-kartu-bez-otkazov/',
  '/zaimy/na-kartu-bez-proverki-kreditnoy-istorii/',
  '/zaimy/na-kartu-bez-proverok-i-zvonkov/',
  '/zaimy/na-kartu-do-zarplaty/',
  '/zaimy/na-kartu-kukuruza-online/',
  '/zaimy/na-kartu-mastercard/',
  '/zaimy/na-kartu-mir/',
  '/zaimy/na-kartu-momentum/',
  '/zaimy/na-kartu-sberbanka-do-zarplaty/',
  '/zaimy/na-kartu-s-nulevym-balansom/',
  '/zaimy/na-kartu-tinkoff/',
  '/zaimy/na-kartu-visa/',
  '/zaimy/na-kartu-vtb/',
  '/zaimy/na-kartu-za-5-minut/',
  '/zaimy/na-kivi-bez-karty/',
  '/zaimy/na-kivi-bez-otkazov/',
  '/zaimy/na-kivi-bez-pasporta/',
  '/zaimy/na-kivi-bez-procentov/',
  '/zaimy/na-kivi-bez-zvonkov/',
  '/zaimy/na-kivi-pensioneram/',
  '/zaimy/na-kivi-po-pasportu/',
  '/zaimy/na-kivi-s-18-let/',
  '/zaimy/na-kivi-virtualnuju-kartu/',
  '/zaimy/na-kreditnuju-kartu/',
  '/zaimy/na-neimennuyu-kartu/',
  '/zaimy/na-noviy-god/',
  '/zaimy/na-polgoda-6-mesyacev/',
  '/zaimy/na-sberknizhku/',
  '/zaimy/na-telefon/',
  '/zaimy/na-virtualnuyu-kartu/',
  '/zaimy/na-yandex/',
  '/zaimy/na-yandex-bez-privjazki-karty/',
  '/zaimy/na-zolotuyu-koronu/',
  '/zaimy/ne-vyhodja-iz-doma/',
  '/zaimy/nochju/',
  '/zaimy/novye-mfo/',
  '/zaimy/pensioneram/',
  '/zaimy/perevodom/',
  '/zaimy/pod-0-protsentov/',
  '/zaimy/pod-pts/',
  '/zaimy/pod-pts-avtolombardy/',
  '/zaimy/pod-pts-bez-avto/',
  '/zaimy/pod-pts-po-doverennosti/',
  '/zaimy/pod-raspisku/',
  '/zaimy/pod-zalog-avtomobilya-s-pravom-polzovaniya/',
  '/zaimy/pod-zalog-documentov/',
  '/zaimy/pod-zalog-gruzovogo-avtomobilja/',
  '/zaimy/pod-zalog-motocykla/',
  '/zaimy/pod-zalog-spectehniki/',
  '/zaimy/po-pasportu/',
  '/zaimy/po-sms/',
  '/zaimy/po-telefonu/',
  '/zaimy/po-vsej-rossii/',
  '/zaimy/proverennyye-zaymy-do-30-000/',
  '/zaimy/refinansirovanie/',
  '/zaimy/robot/',
  '/zaimy/s-19-let-na-kartu/',
  '/zaimy/s-21-goda/',
  '/zaimy/s-avtomaticheskim-odobreniem-na-kartu-online/',
  '/zaimy/s-chernym-spiskom/',
  '/zaimy/servis/',
  '/zaimy/s-ezhemesyachnym-platezhom/',
  '/zaimy/s-ljuboy-ki/',
  '/zaimy/srazu/',
  '/zaimy/srochno-100/',
  '/zaimy/srochno-1000/',
  '/zaimy/srochno-10000/',
  '/zaimy/srochno-100000/',
  '/zaimy/srochno-1000000/',
  '/zaimy/srochno-12000/',
  '/zaimy/srochno-1500/',
  '/zaimy/srochno-15000/',
  '/zaimy/srochno-150000/',
  '/zaimy/srochno-2000/',
  '/zaimy/srochno-20000/',
  '/zaimy/srochno-200000/',
  '/zaimy/srochno-25000/',
] as const;

export const PAGES_WITH_CALCULATOR_SET: Set<string> = new Set(PAGES_WITH_CALCULATOR);
