import debounce from 'lodash/debounce';
import { useCallback, useEffect, useMemo, useRef } from 'react';

import { NumberInput } from '@sravni/react-design-system';

import { RATE_SETTINGS } from '@src/constants/filters';
import { validateByParams } from '@src/helpers/validate';

interface IProps {
  className?: string;
  value?: number;
  name: string;
  label: string;
  placeholder?: string;
  error?: string;
  min?: number;
  max?: number;
  onChange: (value: number | undefined, name: string) => void;
  postfix?: string;
  analyticsEvent?: (label: string, value: string) => void;
}

export const RateControl: React.FC<IProps> = ({
  className,
  value,
  name,
  label,
  placeholder,
  error,
  onChange,
  postfix,
  analyticsEvent,
  min = RATE_SETTINGS.min,
  max = RATE_SETTINGS.max,
  ...props
}) => {
  const validate = useMemo(() => validateByParams({ max }), [max]);
  const validatedValue = validate(value);

  const handleChange = useRef(onChange);

  useEffect(() => {
    // валидация: если в query-параметрах кто-то вручную исправит значение
    if (validatedValue !== value) {
      handleChange.current(validatedValue, name);
    }
  }, [name, validatedValue, value]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleChangeRateInput = useCallback(
    debounce((value = 0) => {
      if (value >= min && value <= max) {
        analyticsEvent?.(label, value.toString());
        handleChange.current(value, name);
      }
    }, 1000),

    [max, min, name, label, analyticsEvent],
  );

  return (
    <NumberInput
      postfix={postfix}
      label={error || label}
      className={className}
      placeholder={placeholder}
      value={validatedValue}
      defaultValue={0}
      decimalScale={2}
      min={min}
      max={max}
      invalid={Boolean(error)}
      thousandSeparator=" "
      inputMode="numeric"
      onChange={handleChangeRateInput}
      {...props}
    />
  );
};
